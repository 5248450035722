<template>
  <b-card
    v-if="userData"
    class="card-congratulation-medal"
  >
	<h4>Compte BUXIDA actuel : <b-badge>{{ userData.C_RS }}</b-badge></h4>
	<b-form-group
		label="Liste des sites actifs"
		label-for="soc"		
	 >
		<b-form-select
		id="soc"
		v-model="userData.C_UISOC"
		:options="resultSoc"
		/>
	</b-form-group>
    <b-button
		v-ripple.400="'rgba(255, 255, 255, 0.15)'"
		variant="primary"
		@click="majsoc()"
    >
      Changer de compte
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BFormGroup, BFormSelect, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
	components: {
		BCard,
		BButton,
		BFormGroup,
		BFormSelect,
		BBadge,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			userData: {},
			user: {},
		}
	},
	props: {
		resultSoc: {
			type: Object,
			default: () => [],
		},
	},
	methods: {
		majsoc() {
			this.$http.post('/admin/majsoc', { CUI: this.userData.C_UISOC })
			.then(response => {
				if (response.data.res === 1) {
					this.userData.C_UISOC = response.data.C_UISOC
					this.userData.C_RS = response.data.C_RS
					localStorage.setItem('userData', JSON.stringify(this.userData))
					this.$toast({
					component: ToastificationContent,
					position: 'top-right',
						props: {
						  title: 'Ok c\'est fait ! 🎉',
						  icon: 'CheckIcon',
						  variant: 'success',
						  text: 'Tu peut te connecter sur BUXIDA !',
						},
					},
					{
					timeout: 8000,
					position: 'top-center',
					})
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'danger',
							text: 'Erreur : activation impossible',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})
				}
			})	
		},
  	},
	created() {
		this.userData = JSON.parse(localStorage.getItem('userData'))
	},	
}
</script>
